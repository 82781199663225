import Navbar from '~/components/navbar'
import Button from '~/components/ui/button'
import { WebHeader } from '~/components/ui/page-header'
import Feedback from '~/components/Feedback'

export default function NotFound() {
    return (
        <>
            <Feedback />
            <Navbar />
            <WebHeader
                title={<>404</>}
                description={<>Page not found</>}
                actions={
                    <>
                        <Button
                            as='a'
                            href='/'
                            $size='medium'
                            $style='ghost'
                            fullWidth
                        >
                            Back to Home
                        </Button>
                    </>
                }
            />
        </>
    )
}
